import {AppDispatch} from "../index";
import axios from "axios";
import {authSlice} from "../reducers/authSlice";
import {getErrorMessage} from "../../helpers";


export const checkEmail = (email: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(authSlice.actions.isFetching())
        const response = await axios.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/accounts/availability/email?Email=${email}`)

        if (response.data.succeeded) {
            return response.data.value
        } else {

            dispatch(authSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }

    } catch (error: any) {
        const message = getErrorMessage(error)
        dispatch(authSlice.actions.isFailure(message))
    }
}

export const clearSuccessMessage = () => async (dispatch: AppDispatch) => {
    dispatch(authSlice.actions.clearSuccess())
}

export const setPasswordResetData = (data: { email: string }) => async (dispatch: AppDispatch) => {
    try {
        dispatch(authSlice.actions.addResetPasswordData(data))
        dispatch(authSlice.actions.sendConfirmationTokenFetching())
        const response = await axios.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/tokens/email/send`,
            {
                email: data.email,
                purpose: 1
            })

        if (response.data.succeeded) {
            dispatch(authSlice.actions.sendConfirmationTokenSuccess('Токен успешно отправлен'))
            return true
        } else {
            dispatch(authSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
            return false
        }

    } catch (error: any) {
        const message = getErrorMessage(error)
        dispatch(authSlice.actions.sendConfirmationTokenFailure(message))

    }
}

export const resendConfirmationToken = (email: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(authSlice.actions.sendConfirmationTokenFetching())
        const response = await axios.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/tokens/email/send`,
            {
                email: email,
                purpose: 0
            })
        if (response.data.succeeded) {
            dispatch(authSlice.actions.sendConfirmationTokenSuccess('Токен успешно отправлен'))
            return true
        } else {
            dispatch(authSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
            return false
        }

    } catch (error: any) {
        const message = getErrorMessage(error)
        dispatch(authSlice.actions.sendConfirmationTokenFailure(message))

    }
}

export const passwordReset = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(authSlice.actions.addResetPasswordData(data))
        dispatch(authSlice.actions.passwordResetFetching())
        const response = await axios.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/accounts/password/reset`, data)

        if (response.data.succeeded) {
            dispatch(authSlice.actions.passwordResetSuccess("Пароль успешно изменён"))
            return true
        } else {
            dispatch(authSlice.actions.passwordResetFailure(response.data.errors[0].ErrorMessages[0]))
            return false
        }


    } catch (error: any) {
        const message = getErrorMessage(error)
        dispatch(authSlice.actions.registerUserFailure(message))
    }
}

export const setRegisterData = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(authSlice.actions.addRegisterData(data))
        dispatch(authSlice.actions.sendConfirmationTokenFetching())
        const response = await axios.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/tokens/email/send`,
            {
                email: data.email,
                purpose: 0
            })

        if (response.data.succeeded) {
            dispatch(authSlice.actions.sendConfirmationTokenSuccess('Токен успешно отправлен'))
            return true
        } else {
            dispatch(authSlice.actions.sendConfirmationTokenFailure(response.data.errors[0].ErrorMessages[0]))
            return false
        }

    } catch (error: any) {
        const message = getErrorMessage(error)
        dispatch(authSlice.actions.sendConfirmationTokenFailure(message))
    }
}

export const registerUser = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(authSlice.actions.addRegisterData(data))
        dispatch(authSlice.actions.registerUserFetching())
        const response = await axios.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/accounts/signup`, data)

        if (response.data.succeeded) {
            dispatch(authSlice.actions.registerUserSuccess())
        } else {
            dispatch(authSlice.actions.registerUserFailure(response.data.errors[0].ErrorMessages[0]))
        }


    } catch (error: any) {
        const message = getErrorMessage(error)
        dispatch(authSlice.actions.registerUserFailure(message))
    }
}

export const authorizeUser = (data: any) => async (dispatch: AppDispatch) => {
    try {
        dispatch(authSlice.actions.isFetching())
        await axios.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/accounts/signin?returnUrl=${process.env.REACT_APP_API_URI_HTTPS}/connect/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}%26response_type=code%26nonce=xxadadass%26scope=usermanager.access worktasks.access candidates.access commoncatalogs.access usermanager.access recruiting.access vacancies.access kladr.access openid profile offline_access%26redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`, data, {
            withCredentials: true
        })
            .then(res => {
                window.location.href = res.data.returnUrl
            })
            .catch(error => {

                dispatch(authSlice.actions.isLoginFailure(error.response.data.errors[0].ErrorMessages[0]))
            })
    } catch (error: any) {
        const message = getErrorMessage(error)
        dispatch(authSlice.actions.isFailure(message))
    }
}

export const setCredentials = (code: string) => async (dispatch: AppDispatch) => {
    try {

        // @ts-ignore
        const response = await axios.post(`${process.env.REACT_APP_API_URI_HTTPS}/connect/token`, new URLSearchParams({
            client_id: process.env.REACT_APP_CLIENT_ID,
            redirect_uri: process.env.REACT_APP_REDIRECT_URI,
            response_type: 'code',
            nonce: 'xxadadass',
            scope: 'usermanager.access worktasks.access candidates.access commoncatalogs.access usermanager.access recruiting.access vacancies.access kladr.access openid profile offline_access',
            grant_type: 'authorization_code',
            code: code
        }))

        dispatch(authSlice.actions.setCredentialsSuccess(response.data))
    } catch (error: any) {
        const message = getErrorMessage(error)
        dispatch(authSlice.actions.setCredentialsFailure(message))
    }
}

export const logout = () => async (dispatch: AppDispatch) => {
    dispatch(authSlice.actions.removeCredentials())
}


export const getUserInfo = (token: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(authSlice.actions.isFetching())
        // @ts-ignore
        const response = await axios.get(`${process.env.REACT_APP_API_URI_HTTPS}/api/accounts/info`,
            {headers: {Authorization: `Bearer ${token}`}})

        if (response.data.succeeded) {
            dispatch(authSlice.actions.setUserInfo(response.data.value))
        } else {
            dispatch(authSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error: any) {
        const message = getErrorMessage(error)
        dispatch(authSlice.actions.isFailure(message))
    }
}

export const updateUserInfo = (data: any, token: string) => async (dispatch: AppDispatch) => {
    try {

        dispatch(authSlice.actions.isFetching())
        // @ts-ignore
        const response = await axios.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/accounts/info/update`, data,
            {headers: {Authorization: `Bearer ${token}`}})

        if (response.data.succeeded) {
            dispatch(authSlice.actions.isSuccess('Данные успешно изменены'))
        } else {
            dispatch(authSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error: any) {
        const message = getErrorMessage(error)
        dispatch(authSlice.actions.isFailure(message))
    }
}

export const updatePassword = (data: any, token: string) => async (dispatch: AppDispatch) => {
    try {

        dispatch(authSlice.actions.isFetching())
        // @ts-ignore
        const response = await axios.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/accounts/password/update`, data,
            {headers: {Authorization: `Bearer ${token}`}})

        if (response.data.succeeded) {
            dispatch(authSlice.actions.isSuccess('Пароль успешно изменен'))
        } else {
            dispatch(authSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
        }
    } catch (error: any) {
        const message = getErrorMessage(error)
        dispatch(authSlice.actions.isFailure(message))
    }
}


export const acceptInvite = (data: any) => async (dispatch: AppDispatch) => {
    try {

        dispatch(authSlice.actions.isFetching())
        // @ts-ignore
        const response = await axios.post(`${process.env.REACT_APP_API_URI_HTTPS}/api/accounts/invite/accept`, data)

        if (response.data.succeeded) {
            dispatch(authSlice.actions.isSuccess('Вы успешно зарегистрированы'))
            return true
        } else {
            dispatch(authSlice.actions.isFailure(response.data.errors[0].ErrorMessages[0]))
            return false
        }
    } catch (error: any) {
        const message = getErrorMessage(error)
        dispatch(authSlice.actions.isFailure(message))
    }
}
